<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;" :fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id" :defaultValue="corp_id"
						:options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
						
					<a-tree-select
					    v-model="corp_id"
						 @change="corpChange"
					   style="width: 300px;"
					    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
					    :tree-data="corpList"
					    placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }"
					  >
					  </a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="开户名称">
					<a-input size="default" v-model="name" placeholder="输入开户名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="开户银行">
					<a-input size="default" v-model="bank" placeholder="输入开户银行查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item label="银行账号">
					<a-input size="default" v-model="account" placeholder="输入银行账号查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="age5">
					<span class="btn-span">正常</span>
				</div>
				<div slot="age6" slot-scope="text, record">
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="delAcc(record.cba_id)" v-if="record.out_acc_id>0">
						<template slot="title">
							<p>是否确定该结算账号移出银企直连？</p>
						</template>
						<span class="btn-span" >
							移除
						</span>
					</a-popconfirm>
					<span class="btn-span"  @click="addAcc(record.cba_id)" v-if="record.out_acc_id==0">
						对接
					</span>
					<span class="btn-span" @click="edit(record)">
						编辑
					</span>
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.cba_id)">
						<template slot="title">
							<p>是否确定删除该结算账号？</p>
						</template>
						<span class="btn-span btn-span-delete" >
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
				:show-total="(total, range) => `共${total}条记录`"	:page-size="10" />
			</div>
		</div>

		<a-modal :title="!form.cba_id ? '添加' :'编辑'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model :key="formKey" ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="选择客户" prop="corp_id">
					<a-tree-select v-model="form.corp_id" @change="chcorpChange" style="width: 100%;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="开户名称" prop="name">
					<a-input v-model="form.name" placeholder="请输入开户名称" />
				</a-form-model-item>
				<a-form-model-item label="开户银行" prop="bank">
					<a-input v-model="form.bank" placeholder="请输入开户银行" />
				</a-form-model-item>
				<a-form-model-item label="银行账号" prop="account">
					<a-input v-model="form.account" placeholder="请输入银行账号" />
				</a-form-model-item>
				
			</a-form-model>
		</a-modal>

		<a-modal title="是否确定该结算账号对接银企直连？" :visible="modelTimeVisible" @ok="handleTimeOk" @cancel="handleTimeCancel">
			<a-form-model :key="formKey" ref="ruleTimeForm" :model="formTime" :rules="rulesTime" :label-col="labelCol" :wrapper-col="wrapperCol">
				<a-form-model-item label="开始转账时间" prop="start_time">
					<a-date-picker
						format="YYYY-MM-DD HH:mm:ss"
						v-model="formTime.start_time"
						:show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"
						style="width: 300px;"
						placeholder="请选择开始转账时间"
					/>
				</a-form-model-item>
			</a-form-model>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'
	const columns = [{
			title: '客户名称',
			dataIndex: 'corp_name',
			key: 'corp_name',
		},

		{
			title: '开户名称',
			key: 'name',
			dataIndex: 'name',
		},
		{
			title: '开户银行',
			key: 'bank',
			dataIndex: 'bank',
		},
		{
			title: '银行账号',
			key: 'account',
			dataIndex: 'account',
		},
		{
			title: '操作',
			key: 'age6',
			width: 250,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	export default {
		data() {
			return {
				moment,
				modelVisible: false,
				modelTimeVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				formTime: {
					start_time: '',
					id: ''
				},
				rulesTime: {
					start_time: [{
						required: true,
						message: '请选择开始转账时间',
						trigger: 'change'
					}],
				},
				form: {
					corp_id: null,
					name: '',
					bank: '',
					account: '',
					cba_id: 0
				},
				rules: {
					name: [{
						required: true,
						message: '请输入开户名称',
						trigger: 'blur'
					}],
					bank: [{
						required: true,
						message: '请输入开户银行',
						trigger: 'blur'
					}],
					account: [{
						required: true,
						message: '请输入银行账号',
						trigger: 'blur'
					}],
					corp_id: [{
						required: true,
						message: '请选择客户',
						trigger: 'change'
					}],
				},
				
				bank: '',
				name:'',
				account: '',
				corp_id: null,
				tableData: [],
				tableTotal: 0,
				tablePage: 1, 
				corpList: [],
				corpTreeList: [],
				defaultCorp: [],
				formKey: Math.random()
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}
				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {...corpTreeList}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = parseInt(this.defaultCorp)
				this.getTable()
			})
		},
		methods: {
			handleSubmit(e) {
			},
			corpChange (data) {
				this.corp_id = data
			},
			chcorpChange (data) {
				this.form.corp_id = data
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = parseInt(this.defaultCorp)
					this.name = ''
					this.bank = ''
					this.account = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.corp.acc.alist/',
					data: {
						name: this.name,
						bank: this.bank,
						account: this.account,
						page: this.tablePage,
						corp_id: this.corp_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			addAcc(id) {
				this.formTime.id = id
				this.modelTimeVisible = true
			},
			delAcc(id) {
				http({
					method: 'post',
					url: '/api.corp.acc.deloutacc/',
					data: {
						cba_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('结算账号移除成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.corp.acc.del/',
					data: {
						cba_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除结算账号成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				let cp = this.corpTreeList[data.corp_name + data.corp_id]
				this.form = {
					...data,
					corp_id: typeof cp == 'number' ? cp : cp[cp.length - 1]
				}
				this.modelVisible = true;
				this.formKey = Math.random()
			},
			handleTimeOk () {
				this.$refs.ruleTimeForm.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.corp.acc.addoutacc/',
							data: {
								cba_id: this.formTime.id,
								start_time: moment(this.formTime.start_time).format('YYYY-MM-DD HH:mm:ss')
							}
						}).then((res) => {
							if (res.code == "200") {
								this.$message.success('结算账号对接成功');
								this.handleTimeCancel()
								this.getTable()
							} else {
								if (res.message == '值已存在') {
									this.handleTimeCancel()
								}
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});
			},
			handleTimeCancel() {
				this.modelTimeVisible = false;
				this.formTime.start_time = ''
			},
			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form
						}
						delete newData.corp_name
						delete newData.out_acc_id
						if (typeof newData.corp_id == 'object') {
							newData.corp_id = newData.corp_id[newData.corp_id.length - 1]
						}
						http({
							method: 'post',
							url: '/api.corp.acc.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.cba_id) {
									this.$message.success('编辑结算账号信息成功');
								} else {
									this.$message.success('添加结算账号成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					corp_id: null,
					name: '',
					bank: '',
					account: '',
					cba_id: 0
				}
				this.formKey = Math.random()
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>
