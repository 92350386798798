<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline">
				<a-form-model-item label="角色名称">
					<a-input size="default" v-model="role_name" placeholder="输入角色名称查询">
					</a-input>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
			<a-button type="primary" @click="modelVisible = true" icon="plus-circle">
				添加
			</a-button>
		</div>

		<div class="table-box">
			<a-table :columns="columns" :data-source="tableData" :pagination="false">
				<a slot="name" slot-scope="text">{{ text }}</a>
				<div slot="type_id" slot-scope="text, record">
					<a-tag color="cyan" v-if="record.type_id == 1">
						系统
					</a-tag>
					<a-tag color="green" v-if="record.type_id == 2">
						运营商
					</a-tag>
				</div>
				<div slot="status" slot-scope="text, record">
					<a-tag color="blue" v-if="record.status == 1">
						启用
					</a-tag>
					<a-tag color="orange" v-if="record.status == 0">
						隐藏
					</a-tag>
				</div>
				<div slot="age6" slot-scope="text, record">
					<span class="btn-span" @click="edit(record)">
						编辑
					</span>
					<span class="btn-span" @click="auto(record)">
						权限
					</span>
					<a-popconfirm placement="top" ok-text="确定" cancel-text="取消" @confirm="remove(record.role_id)">
						<template slot="title">
							<p>是否确定删除该角色？</p>
						</template>
						<span class="btn-span btn-span-delete">
							删除
						</span>
					</a-popconfirm>
				</div>
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
					:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>

		<a-modal :title="form.role_id ? '编辑' : '添加'" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">

				<a-form-model-item label="角色名称" prop="role_name">
					<a-input v-model="form.role_name" placeholder="请输入角色名称" />
				</a-form-model-item>

				<a-form-model-item label="角色类型">
					<a-radio-group v-model="form.type_id">
						<a-radio :value="1">
							系统
						</a-radio>
						<a-radio :value="2">
							运营商
						</a-radio>
					</a-radio-group>
				</a-form-model-item>

				<a-form-model-item label="是否启用">
					<a-radio-group v-model="form.status">
						<a-radio :value="1">
							启用
						</a-radio>
						<a-radio :value="0">
							隐藏
						</a-radio>
					</a-radio-group>
				</a-form-model-item>
			</a-form-model>
		</a-modal>

		<a-modal v-model="authorityShow" title="设置角色权限" width="840px" @ok="handleAuthOk" @cancel="handleAuthCancel">
			<a-spin :spinning="detailLoading">
				<p style="width: 95%;margin: 0 auto; margin-bottom: 20px;font-size: 16px;">角色名称： {{roleinfo.role_name}}
				</p>
				<div class="tes roleee">
					<div class="taes">
						<div class="contet">
							<div class="chebox" v-for="(item, index) in authorityList" :key="index">
								<div class="chebox-item">
									<a-checkbox :checked="item.check" @change="onChangeCheck(index, item.check)">
										{{item.router_name}}
									</a-checkbox>
								</div>
							</div>
						</div>
						<div class="right">
							<div class="chebox" v-for="(item, index) in authorityList" :key="index">
								<div class="chebox-item">
									<a-checkbox-group v-model="item.checks" :options="item.list"> <span slot="label"
											slot-scope="{ value }">{{ value }}</span> </a-checkbox-group>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="teslast roleee">
					<div class="taes">
						<div class="contet">
							<div class="chebox">
								<div class="chebox-item">
									
								</div>
							</div>
						</div>
						<div class="right" style="min-height: 100px;">
							<div class="chebox">
								<div class="chebox-item">
									<a-checkbox v-for="item in btnList" :checked="item.check" @change="item.check = !item.check">
										{{item.label}}
									</a-checkbox>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import http from '@/http'
	const columns = [{
			title: '角色名称',
			dataIndex: 'role_name',
			key: 'role_name',
		},
		{
			title: '角色类型',
			dataIndex: 'type_id',
			key: 'type_id',
			scopedSlots: {
				customRender: 'type_id'
			},
		},
		{
			title: '状态',
			dataIndex: 'status',
			key: 'status',
			scopedSlots: {
				customRender: 'status'
			},
		},
		{
			title: '操作',
			key: 'age6',
			width: 250,
			scopedSlots: {
				customRender: 'age6'
			},
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				labelCol: {
					span: 6
				},
				wrapperCol: {
					span: 14
				},
				other: '',
				form: {
					role_id: 0,
					role_name: '',
					type_id: 1,
					status: 1
				},
				rules: {
					role_name: [{
						required: true,
						message: '请输入角色名称',
						trigger: 'blur'
					}],
				},

				role_name: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,

				authorityList: [],
				btnList: [],
				authorityShow: false,
				detailLoading: false,
				roleinfo: {}
			};
		},
		mounted() {
			this.getTable()


		},
		methods: {
			handleSubmit(e) {},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.role_name = ''
					this.tablePage = 1
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.admin.role.list/',
					data: {
						role_name: this.role_name,
						page: this.tablePage
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			remove(id) {
				http({
					method: 'post',
					url: '/api.admin.role.del/',
					data: {
						role_id: id,
					}
				}).then((res) => {
					if (res.code == "200") {
						this.$message.success('删除角色成功');
						this.getTable()
					} else {
						this.$message.warning(res.message);
					}
				})
			},
			edit(data) {
				this.form = {
					...data
				}
				this.modelVisible = true;
			},

			handleOk() {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						let newData = {
							...this.form
						}
						http({
							method: 'post',
							url: '/api.admin.role.add/',
							data: newData
						}).then((res) => {
							if (res.code == "200") {
								if (this.form.role_id) {
									this.$message.success('编辑角色信息成功');
								} else {
									this.$message.success('添加角色成功');
								}
								this.getTable()
								this.modelVisible = false;
								this.initFormData()
							} else {
								this.$message.warning(res.message);
							}
						})
					} else {
						return false;
					}
				});

			},
			handleCancel() {
				this.modelVisible = false;
				this.initFormData()
			},
			initFormData() {
				this.form = {
					role_id: 0,
					role_name: '',
					type_id: 1,
					status: 1
				}
				this.formKey = Math.random()
			},

			auto(data) {
				this.roleinfo = data
				this.authorityShow = true
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.admin.authority.list/',
					data: {
						role_id: data.role_id
					}
				}).then((res) => {
					if (res.code == '200') {
						let btns = []
						console.log(res.data, 'res.datares.data')
						this.authorityList = res.data.map((v) => {
							let checks = []
							if (v.list) {
								v.list.forEach((vi) => {
									if (vi.is_able == 1) {
										checks.push(vi.parent_id + ',' + vi.router_id)
									}

									if (vi.list) {
										vi.list.forEach((vi3) => {
											btns.push({
												...vi3,
												check: vi3.is_able == 0 ? false :
													true,
											})
										})
									}
								})
							}
							return {
								...v,
								check: v.is_able == 0 ? false : true,
								list: v.list ? v.list.map((vi) => {
									return {
										value: vi.parent_id + ',' + vi.router_id,
										label: vi.router_name
									}
								}) : [],
								checks: checks
							}
						})
						this.btnList = btns.map((vi) => {
							return {
								value: vi.parent_id + ',' + vi.router_id,
								label: vi.router_name,
								check: vi.check
							}
						})
						this.detailLoading = false
					} else {
						this.$message.error(res.message);
					}
				})
			},
			onChangeCheck(index, check) {
				this.authorityList[index].check = !check
				if (this.authorityList[index].check) {
					let checks = []
					this.authorityList[index].list.forEach((v) => {
						checks.push(v.value)
					})
					this.authorityList[index].checks = checks
				} else {
					this.authorityList[index].checks = []
				}
			},
			handleAuthCancel() {
				this.authorityShow = false
			},
			handleAuthOk() {
				let checks = []
				this.authorityList.forEach((v) => {
					if (v.check) {
						checks.push(v.router_id)
					}
					v.checks.forEach((vi) => {
						let ids = vi.split(",")
						checks.push(ids[1])
					})
				})
				this.btnList.forEach((vi) => {
						let ids = vi.value.split(",")
						checks.push(ids[1])
					})
				http({
					method: 'post',
					url: '/api.admin.authority.add/',
					data: {
						role_id: this.roleinfo.role_id,
						authority: checks.join(',')
					}
				}).then((res) => {
					if (res.code == '200') {
						this.$message.success('设置角色权限成功');
						this.authorityShow = false
					} else {
						this.$message.error(res.message);
					}
				})
			}
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}

	.roleee.tes {
		width: 95%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;

		.taes {
			display: flex;
			align-items: stretch !important;

			&>div {
				width: 20%;

				&:nth-child(2) {
					width: 80%;
				}
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 90px !important;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 10px;
				}
			}

			.left {
				min-height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				border-right: 1px solid #e8e8e8;
				height: auto !important;
			}

			.right {
				min-height: 400px;
			}
		}
	}
	.roleee.teslast {
		width: 95%;
		margin: 0 auto;
		border-left: 1px solid #e8e8e8;
		border-right: 1px solid #e8e8e8;
		border-bottom: 1px solid #e8e8e8;
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;

		.taes {
			display: flex;
			align-items: stretch !important;

			&>div {
				width: 20%;

				&:nth-child(2) {
					width: 80%;
				}
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 90px !important;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 10px;
				}
			}

			.left {
				min-height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				border-right: 1px solid #e8e8e8;
				height: auto !important;
			}

			.right {
				min-height: 400px;
			}
		}
	}
</style>