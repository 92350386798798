<template>
	<div class="content-box-div">
		<div class="filter-box">
			<a-form-model layout="inline" :key="filterKey">
				<a-form-model-item label="客户选择">
					<!-- <a-cascader style="width: 300px;"
						:fieldNames="{label: 'corp_name', value: 'corp_id', children: 'list' }" :key="corp_id"
						:defaultValue="corp_id" :options="corpList" placeholder="请选择客户" @change="corpChange" /> -->
					<a-tree-select v-model="corp_id" @change="corpChange" style="width: 300px;"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" :tree-data="corpList"
						placeholder="请选择客户"
						:replaceFields="{children:'list', title:'corp_name', key:'corp_id', value: 'corp_id' }">
					</a-tree-select>
				</a-form-model-item>
				<a-form-model-item label="区域选择">
					<a-select placeholder="请选择区域" v-model="region_id" style="width: 200px">
						<a-select-option v-for="(item, index) in regionList" :key="index"
							:value="item.region_id">{{ item.region_name }}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="日期查询">
					<a-range-picker @change="onChange" :disabledDate="disabledDate" style="width:240px;">
						<a-icon slot="suffixIcon" type="smile" />
					</a-range-picker>
				</a-form-model-item>
				<a-form-model-item>
					<div class="btns10">
						<a-button type="primary" icon="search" @click="btnClick('查询')">
							查询
						</a-button>
						<a-button type="primary" icon="sync" @click="btnClick('重置')">
							重置
						</a-button>
						<a-button type="primary" icon="reload" @click="btnClick('刷新')">
							刷新
						</a-button>
					</div>
				</a-form-model-item>
			</a-form-model>
		</div>

		<div class="ew-filter">
			<div></div>
		</div>
		<div class="table-box">
			<a-table rowKey="re_id" :columns="columns" :data-source="tableData" :pagination="false">
			</a-table>
			<div class="table-pagination">
				<a-pagination v-model="tablePage" @change="tabelPageChange" show-quick-jumper :total="tableTotal"
					:show-total="(total, range) => `共${total}条记录`" :page-size="10" />
			</div>
		</div>

		<a-modal title="处理" :visible="modelVisible" @ok="handleOk" @cancel="handleCancel">
			<a-form-model ref="ruleForm" :label-col="{span: 6}" :wrapper-col="{span: 14}">
				<a-form-model-item label="单号" prop="bank">
					<a-input v-model="cno" placeholder="请输入单号" />
				</a-form-model-item>
			</a-form-model>
		</a-modal>

	</div>
</template>

<script>
	import http from '@/http'
	import moment from 'moment'

	const columns = [{
			title: '所属地区',
			dataIndex: 'region_name',
			key: 'region_name',
		}, 
        {
			title: '银行单号',
			dataIndex: 'bank_no',
			key: 'bank_no',
		},
		{
			title: '金额',
			key: 'amount',
			dataIndex: 'amount',
		},
		{
			title: '录入时间',
			key: 'add_time',
			dataIndex: 'add_time',
		},
	];
	export default {
		data() {
			return {
				modelVisible: false,
				columns,
				corp_id: null,
				region_id: undefined,
				start_date: '',
				end_date: '',
				tableData: [],
				tableTotal: 0,
				tablePage: 1,
				corpList: [],
				corpTreeList: [],
				regionList: [],
				defaultCorp: [],
				filterKey: Math.random(),
			};
		},
		mounted() {
			http({
				method: 'post',
				url: '/api.corp.treelist/',
				data: {}
			}).then((res) => {
				this.corpList = res.data
				let corpTreeList = {}

				function forData(data, ids) {
					data.forEach((v) => {
						corpTreeList[v.corp_name + v.corp_id] = [...ids, v.corp_id]
						if (v.list.length) {
							forData(v.list, [...ids, v.corp_id])
						}
					})
				}
				res.data.forEach((v, i) => {
					corpTreeList[v.corp_name + v.corp_id] = v.corp_id
					if (v.list.length) {
						forData(v.list, [v.corp_id])
					}
					if (i == 0) {
						let defaultCorp = {
							...corpTreeList
						}
						let keys = Object.keys(defaultCorp)
						this.defaultCorp = v.corp_id
					}
				})
				this.corpTreeList = corpTreeList
				this.corp_id = this.defaultCorp
				this.getTable()
				this.getRegion()
			})
		},
		methods: {
			onSelectChange(selectedRowKeys) {
				this.selectedRowKeys = selectedRowKeys;
			},
			onSelection(e) {
				console.log(e)
			},
			getRegion() {
				http({
					method: 'post',
					url: '/api.region.treelist/',
					data: {
						corp_id: this.corp_id
					}
				}).then((res) => {
					if (res.code == 200) {
						this.regionList = res.data
						this.getTable()
					}
				})
			},
			disabledDate(current) {
				return current && current > moment().endOf('day')
			},
			onChange(data) {
				this.start_date = moment(data[0]).format('YYYY-MM-DD')
				this.end_date = moment(data[1]).format('YYYY-MM-DD')
			},
			handleSubmit(e) {},
			corpChange(data) {
				this.corp_id = data
				this.region_id = undefined
				this.getRegion()
			},
			chcorpChange(data) {
				this.form.corp_id = data[data.length - 1]
			},
			tabelPageChange(page) {
				this.tablePage = page
				this.getTable()
			},
			btnClick(type) {
				if (type == '查询') {
					this.getTable()
				} else if (type == '重置') {
					this.corp_id = this.defaultCorp
					this.start_date = ''
					this.end_date = ''
					this.region_id = undefined
					this.tablePage = 1
					this.filterKey = Math.random()
					this.getTable()
				} else if (type == '刷新') {
					this.getTable()
				}
			},
			getTable() {
				http({
					method: 'post',
					url: '/api.deposit.remittancelist/',
					data: {
						page: this.tablePage,
						start_date: this.start_date,
						end_date: this.end_date,
						corp_id: this.corp_id,
						region_id: this.region_id == null ? 0 : this.region_id
					}
				}).then((res) => {
					this.tableData = res.data.list
					this.tableTotal = res.data.total_count
				})
			},
			onTableChange(selectedRowKeys, selectedRows) {
				this.expandedRowKeys = selectedRows
			},
		},
	};
</script>

<style lang="less" scoped>
	.table-pagination {
		text-align: right;
		padding: 20px 0px;
	}

	.filter-box {
		padding: 20px;
	}

	.btns10 {
		margin-left: 30px;

		button {
			margin-right: 10px;
		}
	}

	.table-box {
		padding: 0px 20px;
	}

	.ew-filter {
		display: flex;
		justify-content: space-between;
		padding: 0px 20px;
		padding-bottom: 20px;
		align-items: center;
	}
</style>